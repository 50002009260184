import React from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentSection";

const stripePromise = loadStripe('pk_live_51PjRLCAYfSdDPDkJ4dA2Boc1fU3W71IwSvBDmxqgxXmo9c823BHmtk3j9agA7P16fZvG1YraQEsVF6iu5wDXKrne00pEb9V3Fk');

const PaymentPage = () => {
    const location = useLocation();
    const { selectedItems, total, selectedFeatures } = location.state;

    return (
        <Elements stripe={stripePromise}>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Payment</h1>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
                    <div className="bg-white p-6 rounded-md shadow-md max-w-lg md:col-span-1">
                        <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
                        <ul className="divide-y divide-gray-200 mb-4">
                            {selectedItems.map((item, index) => (
                                <li key={index} className="flex justify-between items-center py-2">
                                    <div className="flex items-center space-x-2">
                    {/*
                    IMAGE HERE
                    */}
                                        <div>
                                            <h3 className="font-semibold">{item.name}</h3>
                                            <p className="text-sm text-gray-500">${item.price.toFixed(2)} x {item.quantity}</p>
                                        </div>
                                    </div>
                                    <span>${(item.price * item.quantity).toFixed(2)}</span>
                                </li>
                            ))}
                        </ul>
                        <ul className="divide-y divide-gray-200 mb-4">
                            {selectedFeatures.map((item, index) => (
                                <li key={index} className="flex justify-between items-center py-2">
                                    <div className="flex items-center space-x-2">
                                        <div>
                                            <h3 className="font-semibold">{item.name}</h3>
                                        </div>
                                    </div>
                                    <span>${(item.price).toFixed(2)}</span>
                                </li>
                            ))}
                        </ul>
                        <div className="flex justify-between items-center mt-4">
                            <span className="font-semibold">Subtotal:</span>
                            <span>${total.subtotal}</span>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <span className="font-semibold">Tax (7%):</span>
                            <span>${total.tax}</span>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <span className="font-semibold">Total:</span>
                            <span>${total.totalPrice}</span>
                        </div>
                    </div>

                    <div className="md:col-span-1" />

                    <div className="bg-white p-6 rounded-md shadow-md max-w-lg md:col-span-2">
                        <PaymentForm total={total} selectedItems={selectedItems} selectedFeatures={selectedFeatures} />
                    </div>
                </div>
            </div>
        </Elements>
    );
};

export default PaymentPage;
