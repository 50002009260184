import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import gyro from '../../img/gyro.jpg';
import gyro2 from '../../img/gyro2.jpeg';
import platter from '../../img/platter.jpg';
import cheesetake from '../../img/cheesetake.jpeg';

function Partwo() {
  useEffect(() => {
    let animationsPlayed = false;

    const handleScroll = () => {
      if (animationsPlayed) {
        return;
      }
      const titleElement = document.querySelector(".title-animation");
      const cardsElements = document.querySelectorAll(".card-animation");

      const titleOffset = titleElement.getBoundingClientRect().top;
      const cardsOffset = cardsElements[0].getBoundingClientRect().top;

      const TriggerOffset = window.innerHeight * 0.7;

      if (titleOffset < TriggerOffset) {
        anime({
          targets: ".title-animation",
          translateY: [-50, 0],
          opacity: [0, 1],
          duration: 800,
          delay: anime.stagger(200),
          easing: "easeInOutSine",
          complete: () => {
            animationsPlayed = true;
          },
        });
      }

      if (cardsOffset < TriggerOffset) {
        anime({
          targets: ".card-animation",
          translateX: [-50, 0],
          opacity: [0, 1],
          duration: 800,
          delay: anime.stagger(200, { start: 300 }),
          easing: "easeInOutSine",
          complete: () => {
            animationsPlayed = true;
          },
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="relative">
      <div className="relative pt-72 pb-32 flex content-center items-center justify-center">
        <div
          className="absolute top-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${gyro2})` }}
        >
          <span className="absolute top-0 left-0 w-full h-full bg-opacity-70 bg-black"></span>
          <span className="hidden dark:block absolute top-0 left-0 w-full h-full bg-opacity-60 bg-black"></span>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6-/12 px-4 ml-auto mr-auto text-center pt-32">
                <h1 className="text-white font-semibold text-5xl title-animation">
                  Our Favorite Dishes
                </h1>
                <p className="mt-4 text-lg text-white">
                  Dish list{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="pb-10 -mt-24 bg-white dark:bg-second-dark">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center card-animation">
              <div className="relative flex flex-col w-full mb-8 shadow-xl rounded-lg">
                <div className="p-3 text-center items-center justify-center inline-flex">
                  <img alt="" src={gyro}></img>
                </div>
                <h6 className="text-orange-900 text-xl font-lore font-semibold p-2 dark:text-white">GYRO</h6>
                <p className="text-orange-900 text-sm font-roboto p-2 dark:text-white">
                TENDER BEEF & LAMB STEAK THINLY SLICED OFF THE SLOW ROASTING ROTISSERIE.
                </p>
              </div>
            </div>

            <div className="lg:pt-4 pt-6 w-full md:w-4/12 px-4 text-center card-animation">
              <div className="relative flex flex-col w-full mb-8 shadow-lg rounded-lg">
                <div className="p-3 text-center items-center justify-center inline-flex">
                  <img alt="" src={cheesetake}></img>
                </div>
                <h6 className="text-orange-900 text-xl font-lore font-semibold p-2 dark:text-white">
                BURGERS AND CHEESESTEAKS
                </h6>
                <p className="text-orange-900 text-sm font-roboto p-2 dark:text-white uppercase">
                The freshly shaved beef and lamb steak from the rotisserie is mixed and cooked with your
                 choice of American cheese, Cheese Wiz or Mozzarella, along with Fried Onions
                </p>
              </div>
            </div>

            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center card-animation">
              <div className="relative flex flex-col w-full mb-8 shadow-lg rounded-lg">
                <div className="p-3 text-center items-center justify-center inline-flex">
                  <img alt="" src={platter}></img>
                </div>
                <h6 className="text-orange-900 text-xl font-lore font-semibold p-2 dark:text-white">
                PLATTERS
                </h6>
                <p className="text-orange-900 text-sm font-roboto p-2 dark:text-white">
                PLATTERS WILL BE SERVED WITH ALL THREE SIDES OF FRENCH FRIES, RICE, CHOPPED SALAD, 
                A CUP OF TZATZIKI (White Sauce), AND A SMALL WRAP TO MAKE YOUR OWN WRAP WITHIN THE PLATTER
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Partwo;
