import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CategoryScroll from "../../components/CategoryScroll";
function OrderPage() {
  const [categories, setCategories] = useState([]);
  const [foods, setFoods] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await fetch('https://app.gyrosnj.com/categories');
        const foodResponse = await fetch('https://app.gyrosnj.com/foods');
  
        if (!categoryResponse.ok || !foodResponse.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const categoryData = await categoryResponse.json();
        const foodData = await foodResponse.json();
  
        setCategories(categoryData);
        
        const sortedFoodData = foodData.sort((a, b) => b.order - a.order);
        setFoods(sortedFoodData);
  
        if (categoryData.length > 0) {
          setSelectedCategory(categoryData[0]._id);
        }
  
        console.log(sortedFoodData);
      } catch (error) {
        console.error('Error fetching categories or foods:', error);
      }
    };
  
    fetchData();
  }, []);
  
  
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleItemClick = (item) => {
    setSelectedItems(prevItems => {
      const existingItem = prevItems.find(selectedItem => selectedItem._id === item._id);

      if (existingItem) {
        // If the item is already in the cart, just increase its quantity by 1
        return prevItems.map(selectedItem =>
          selectedItem._id === item._id
            ? { ...selectedItem, quantity: selectedItem.quantity + 1 }
            : selectedItem
        );
      } else {
        // If the item is not in the cart, add it with quantity 1
        return [...prevItems, { ...item, quantity: 1 }];
      }
    });
  };

  const handleIncreaseQuantity = (item) => {
    setSelectedItems(prevItems => {
      return prevItems.map(selectedItem =>
        selectedItem._id === item._id
          ? { ...selectedItem, quantity: selectedItem.quantity + 1 }
          : selectedItem
      );
    });
  };

  const handleDecreaseQuantity = (item) => {
    setSelectedItems(prevItems => {
      const updatedItems = prevItems.map(selectedItem =>
        selectedItem._id === item._id
          ? { ...selectedItem, quantity: selectedItem.quantity - 1 }
          : selectedItem
      );

      // Filter out the item if its quantity is 0
      return updatedItems.filter(selectedItem => selectedItem.quantity > 0);
    });
  };

  const calculateTotalPrice = () => {
    // Sepetteki ürünlerin toplam fiyatını hesapla
    let subtotal = selectedItems.reduce((acc, item) => acc + (item.price * item.quantity), 0);

    // Seçilen özelliklerin toplam fiyatını hesapla
    const featuresTotal = selectedFeatures.reduce((acc, feature) => acc + feature.price, 0);

    const tax = (subtotal + featuresTotal) * 0.07; // Vergi oranı: %7
    const totalPrice = subtotal + featuresTotal + tax;

    return {
      subtotal: (subtotal + featuresTotal).toFixed(2),
      tax: tax.toFixed(2),
      totalPrice: totalPrice.toFixed(2),
    };
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      alert("You must select items to place an order.");
    } else {
      setShowSummary(true);
    }
  };

  const handleAcceptOrder = () => {
    const { totalPrice } = calculateTotalPrice();

    if (parseFloat(totalPrice) === 0) {
      alert("The total amount must be greater than 0 to proceed to payment.");
      return;
    }

    navigate('/payment', { state: { selectedItems, selectedFeatures, total: calculateTotalPrice() } });
  };


  const handleHideSummary = () => {
    setShowSummary(false);
  };

  const filteredFoods = selectedCategory ? foods.filter(food => food.category === selectedCategory) : foods;

  const handleFeatureChange = (chosenFeature) => {
    var featureExists = false;
    selectedFeatures.map((feature) => {
      if (feature._id === chosenFeature._id) {
        setSelectedFeatures(prevFeatures => {
          return prevFeatures.filter(f => f._id !== chosenFeature._id);
        })
        featureExists = true;
      }
    })
    if (!featureExists) {
      selectedFeatures.push(chosenFeature);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Place Your Order</h1>

      <CategoryScroll
              categories={categories}
              handleCategoryClick={handleCategoryClick}
              selectedCategory={selectedCategory}
      />


      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredFoods.map((item) => (
          <MenuItem
            key={item._id}
            {...item}
            quantity={selectedItems.find((selectedItem) => selectedItem._id === item._id)?.quantity || 0}
            features={item.features}
            onItemClick={() => handleItemClick(item)}
            onIncrease={() => handleIncreaseQuantity(item)}
            onDecrease={() => handleDecreaseQuantity(item)}
            onChange={handleFeatureChange}
          />
        ))}
      </div>

      {showSummary && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
          <div className="bg-white p-6 rounded-md shadow-md max-w-lg overflow-y-auto max-h-screen">
            <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
            <ul className="divide-y divide-gray-200 mb-4">
              {selectedItems.map((item) => (
                <li key={item._id} className="flex justify-between items-center py-2">
                  <div className="flex items-center space-x-2">
                    {/*
                    IMAGE HERE
                    */}
                    <div>
                      <h3 className="font-semibold">{item.name}</h3>
                      <p className="text-sm text-gray-500">${item.price} x {item.quantity}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleDecreaseQuantity(item)}
                      className="bg-orange-500 hover:bg-orange-600 text-white px-3 py-1 rounded-md"
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      onClick={() => handleIncreaseQuantity(item)}
                      className="bg-orange-500 hover:bg-orange-600 text-white px-3 py-1 rounded-md"
                    >
                      +
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="flex flex-col mb-4">
              {selectedFeatures.length > 0 && (
                <div>
                  <h3 className="text-lg font-bold">Selected Features:</h3>
                  <ul className="divide-y divide-gray-200">
                    {selectedFeatures.map((feature) => (
                      <li key={feature._id} className="flex justify-between items-center py-2">
                        <div className="flex items-center text-md text-gray-600">
                          {feature.name}
                          <span className="ml-2 text-sm text-gray-500">(+${feature.price})</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex justify-between items-center mt-4">
              <span className="font-semibold">Subtotal:</span>
              <span>${calculateTotalPrice().subtotal}</span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="font-semibold">Tax (7%):</span>
              <span>${calculateTotalPrice().tax}</span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="font-semibold">Total:</span>
              <span>${calculateTotalPrice().totalPrice}</span>
            </div>
            <div className="flex justify-end mt-4 space-x-2">
              <button
                onClick={handleHideSummary}
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
              <button
                onClick={handleAcceptOrder}
                className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-md"
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </div>
      )}


      <button
        onClick={handleSubmit}
        className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 mt-2 rounded-md"
      >
        Review Order
      </button>
    </div>
  );
}

function MenuItem({ name, price, image, description, quantity, features, onItemClick, onIncrease, onDecrease, onChange }) {
  const groupedFeatures = features.reduce((acc, feature) => {
    const { title } = feature;
    if (!acc[title]) {
      acc[title] = [];
    }
    acc[title].push(feature);
    return acc;
  }, {});

  return (
    <div className="bg-white shadow-md rounded-md overflow-hidden">
                    {/*
                    IMAGE HERE
                           <img src={image} alt={name} className="w-full h-48 object-cover" />
                    */}
        
      <div className="p-4">
        <h3 className="text-lg font-semibold">{name}</h3>
        <p className="text-gray-800">{description}</p>
        <p className="text-gray-500 mb-2">${price}</p>

        <div>
          {Object.entries(groupedFeatures).map(([title, features]) => (
            <div key={title}>
              <h3 className="text-lg font-bold">{title}</h3>
              {features.map((feature, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`feature-${feature._id}`}
                    className="mr-2"
                    onChange={() => {
                      console.log("clicked to: ", feature);
                      onChange(feature)
                    }}
                  />
                  <label htmlFor={`feature-${feature._id}`} className="flex items-center text-md text-gray-600">
                    {feature.name}
                    <span className="ml-2 text-sm text-gray-500">(+${feature.price})</span>
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="mt-4 flex flex-col gap-4 justify-center items-center">
          <button
            onClick={onItemClick}
            className="bg-orange-500 hover:bg-orange-600 text-white px-3 py-1 rounded-md"
          >
            Add to Cart
          </button>
          {quantity > 0 && (
            <div className="flex items-center space-x-2">
              <button
                onClick={onDecrease}
                className="bg-orange-500 hover:bg-orange-600 text-white px-3 py-1 rounded-md"
              >
                -
              </button>
              <span>{quantity}</span>
              <button
                onClick={onIncrease}
                className="bg-orange-500 hover:bg-orange-600 text-white px-3 py-1 rounded-md"
              >
                +
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderPage;
