import React from 'react'
import HeaderFixed from '../components/HeaderFixed'
import Footer from '../components/Footer'
import OrderSection from '../sections/Order/OrderSection'

function OrderScreen() {
  return (
    <div>
        <HeaderFixed />
        <OrderSection/>
        <Footer/>
    </div>
  )
}

export default OrderScreen