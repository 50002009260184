import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ContactScreen from './screens/ContactScreen';
import OrderScreen from './screens/OrderScreen';
import PaymentScreen from './screens/PaymentScreen';

function App() {
  return (
    <Router>
        
    <Routes>
        <Route path="/" element={<HomeScreen/>} />
        <Route path="/contact" element={<ContactScreen/>} />
        <Route path='/order' element={<OrderScreen/>} />
        <Route path="/payment" element={<PaymentScreen />} />
    </Routes>
</Router>
  );
}

export default App;
