import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ServiceSection from "../sections/Home/ServiceSection";
import Partwo from "../sections/Home/Partwo";
import About from "../sections/Home/About";

function HomeScreen() {
  return (
    <div>
      <Header></Header>
      <About />
      <ServiceSection />
      <Partwo />
      <Footer />
    </div>
  );
}

export default HomeScreen;
