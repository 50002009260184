import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";

function Footer() {
  useEffect(() => {
    let animationsPlayed = false;

    const handleScroll = () => {
      if (animationsPlayed) {
        return;
      }

      const titleElement = document.querySelectorAll(".title-animation-footer");
      const cardselements = document.querySelectorAll(".card-animation-footer");

      const titleOffset = titleElement[0].getBoundingClientRect().top;
      const cardsOffset = cardselements[0].getBoundingClientRect().top;

      const TriggerOffset = window.innerHeight * 0.9;

      if (titleOffset < TriggerOffset) {
        anime({
          targets: ".title-animation-footer",
          translateY: [-50, 0],
          opacity: [0, 1],
          easing: "easeInOutQuad",
          duration: 400,
          delay: anime.stagger(200, { start: 300 }),
          complete: () => {
            animationsPlayed = true;
          },
        });
      }

      if (cardsOffset < TriggerOffset) {
        anime({
          targets: ".card-animation-footer",
          translateX: [-50, 0],
          opacity: [0, 1],
          easing: "easeInOutQuad",
          duration: 400,
          delay: anime.stagger(200, { start: 300 }),
          complete: () => {
            animationsPlayed = true;
          },
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-primary-light dark:bg-primary-dark shadow-2xl">
      <div className="container mx-auto p-8">
        <footer className="flex flex-wrap lg:flex-nowrap">
          <div className="text-white w-full lg:w-1/2 p-6 flex items-center dark:text-white">
          <div className="card-animation-footer">

            <a className="flex items-center" href="/">
              <img alt="" className="w-96" src="../img/logo3.png" />
            </a>
            </div>
          </div>

          <div className="text-white w-full lg:w-1/2 p-6 dark:text-white">
            <h3 className="text-lg font-semibold mb-4 font-lora title-animation-footer">
              Contact
            </h3>

            <div className="card-animation-footer">
              <p>3300 Fairmount Ave Philadelphia, PA  19104 The USA</p>
              <p>jerseygyronj@gmail.com</p>
              <p>+1 (856) 641-2808</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
