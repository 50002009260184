import React from 'react'
import HeaderFixed from '../components/HeaderFixed'
import Footer from '../components/Footer'
import ContactForm from '../sections/Contact/ContactForm'

function ContactScreen() {
  return (
    <div>
      <HeaderFixed />
      <ContactForm/>
      <Footer/>

    </div>
  )
}

export default ContactScreen