import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../myswiper.css";
import Menu from "./Menu";
import { FaArrowDown } from "react-icons/fa";

function Header() {
  const [, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = document.querySelector("header").offsetHeight;
      setScrolling(window.scrollY > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToContent = () => {
    const content = document.querySelector("#content");
    if (content) {
      content.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative">
      <header className="fixed bg-primary-light text-second-light inset-x-0 top-0 z-50 py-2 border-b border-slate-600 shadow-2xl">
        <div className="py-0">
          <Menu />
        </div>
      </header>
      <div className="pt-16">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <section className="relative h-screen w-full overflow-hidden">
              <div className="absolute w-full h-full bg-center slider-bg"></div>
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-black opacity-15"></div>
              <div className="flex flex-col lg:flex-row justify-center items-center py-28 px-5 mt-28 md:mt-10 md:px-20 relative z-10">
                <div className="">
                  <p className="text-lg lg:text-2xl font-serif text-white">
                  Bringing the true taste of the Philadelphia and Jersey!
                  </p>
                </div>
              </div>
            </section>
          </SwiperSlide>
        </Swiper>
        <div className="arrow-container" onClick={scrollToContent}>
          <FaArrowDown className="arrow-icon" />
        </div>
      </div>
      <div id="content" className="content">
        
      </div>
    </div>
  );
}

export default Header;
