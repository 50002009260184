import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import emailjs from "emailjs-com"; 

const PaymentForm = ({ total, selectedItems, selectedFeatures }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [orderId, setOrderId] = useState(null); 

    const sendEmail = (orderDetails) => {
        emailjs.send("service_q4zob5v","template_o60vx3p",{
            to_name: "JerseyGyro",
            from_name: userName,
            message: "You got new order! Check admin panel.",
            }, "WYon-TzkbPwQbCw8N");
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setPaymentProcessing(true);
        setError(null);
        setSuccessMessage("");

        try {
            // Siparişi önce sunucuya gönderiyoruz (paymentStatus: "unpaid")
            const orderResponse = await axios.post('https://app.gyrosnj.com/orders', {
                userName: userName,
                userPhone: userPhone,
                products: selectedItems,
                amount: total.totalPrice,
                selectedFeatures: selectedFeatures,
                status: "pending",
                paymentStatus: "unpaid",
            });

            // Sunucudan dönen sipariş ID'sini kaydediyoruz
            const orderId = orderResponse.data._id;
            setOrderId(orderId);
            console.log("orderId: ", orderId);
            console.log("paymentStatus: ", orderResponse.data.paymentStatus);

            // Ödeme işlemini başlatıyoruz
            const { data } = await axios.post('https://app.gyrosnj.com/create-payment-intent', {
                amount: total.totalPrice,
            });

            const clientSecret = data.clientSecret;

            const paymentResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: userName,
                        phone: userPhone,
                    },
                },
            });

            if (paymentResult.error) {
                setError(paymentResult.error.message);
                setPaymentProcessing(false);
            } else if (paymentResult.paymentIntent.status === 'succeeded') {
                // Ödeme başarılı, siparişin paymentStatus'ünü güncelle
                await axios.put(`https://app.gyrosnj.com/orders/${orderId}`, {
                    paymentStatus: "paid", // Ödeme başarılıysa paymentStatus paid
                });


                // E-posta gönderme işlemi
                sendEmail();

                setSuccessMessage(
                    "Your payment was successful! Please text this number when you arrive at the lobby: +1 (856) 641-2808"
                );
                alert("Your payment was successful! Please text this number when you arrive at the lobby: +1 (856) 641-2808");
            } else {
                setError("Payment failed. Please try again.");
                setPaymentProcessing(false);
            }
        
        } catch (error) {
            setError("An error occurred during payment processing. Please try again.");
            setPaymentProcessing(false);
        }
    };

    return (
        <form onSubmit={handlePayment}>
            <h2 className="text-2xl font-bold mb-4">Contact Details</h2>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Name
                </label>
                <input
                    type="text"
                    className="block w-full p-3 rounded-md border border-gray-300"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Phone Number
                </label>
                <input
                    type="tel"
                    className="block w-full p-3 rounded-md border border-gray-300"
                    value={userPhone}
                    onChange={(e) => setUserPhone(e.target.value)}
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-semibold mb-2">
                    Card Details
                </label>
                <CardElement className="block w-full p-3 rounded-md border border-gray-300" />
            </div>

            {error && <p className="text-red-500 mb-4">{error}</p>}
            {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

            <button
                type="submit"
                className="block w-full bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded-md"
                disabled={paymentProcessing || !stripe}
            >
                {paymentProcessing ? "Processing..." : "Pay Now"}
            </button>
        </form>
    );
};

export default PaymentForm;
