import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation'; // Ensure these are imported
import 'swiper/css/pagination';
import '../../myswiper.css'; // Your custom Swiper styles
import { Pagination, Navigation } from "swiper/modules";

function About() {
  useEffect(() => {
    anime({
      targets: ".service-item",
      translateY: [100, 0],
      opacity: [0, 1],
      duration: 800,
      delay: anime.stagger(200, { start: 300 }),
      easing: "easeInOutSine",
    });
  }, []);

  const renderText = (text) => (
    <div className="text-center p-4 service-item">
      <p className="bg-primary-light text-white shadow-2xl rounded-lg p-10 dark:text-white dark:bg-primary-dark">
        {text}
      </p>
    </div>
  );

  return (
    <div id="about" className="bg-white dark:bg-second-dark">
      <div className="container mx-auto p-8">
        <div className="p-3 text-center">
          <p className="text-4xl mt-6 mb-10">
            <span className="font-lore text-primary-light dark:text-white">
              About Us
            </span>
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            modules={[Pagination, Navigation]}
            pagination={{ clickable: true }}
            navigation={true}
            className="swiper-container"
          >
            <SwiperSlide><img alt="about1" className="w-full h-auto" src="../img/about3.png" /></SwiperSlide>
            <SwiperSlide><img alt="about3" className="w-full h-auto" src="../img/about.png" /></SwiperSlide>
          </Swiper>
          {renderText(
            "We are a family establishment starting with our grandfather opening his first Mediterranean restaurant in Turkey in 1987. We’ve migrated here in 2008 as a family. We’ve been in business at Jersey Kebab located in Haddon Township for 4 years now. We are now expanding to the city, eager to spread our love through our food."
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
