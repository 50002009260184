import React from 'react'
import HeaderFixed from '../components/HeaderFixed'
import Footer from '../components/Footer'
import PaymentPage from '../sections/Payment/PaymentPage.js'

function PaymentScreen() {
  return (
    <div>
        <HeaderFixed/>
        <PaymentPage/>
        <Footer/>
    </div>
  )
}

export default PaymentScreen