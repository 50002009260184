import React, { useEffect } from "react";
import anime from 'animejs/lib/anime.es.js';

function ServiceSection() {

    useEffect(() => {
        const animateImage = () => {
          anime({
            targets: '.service-item',
            translateY: [100, 0],
            opacity: [0, 1],
            duration: 800,
            delay: anime.stagger(200, { start: 300 }),
            easing: 'easeInOutSine',
          });
        };
        animateImage();
    }, []);

    return (
        <div id="materials" className="bg-primary-light dark:bg-primary-dark">
            <div className="container mx-auto p-8">
                <div className="p-3 text-center">
                    <p className="text-4xl mt-6 mb-10">
                        <span className="font-lore text-white dark:text-white">
                            Business Hours
                        </span>
                    </p>
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-7 gap-4">
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                        <div key={day} className="text-center w-48 p-4 service-item">
                            <img
                                alt={`Day ${day}`}
                                className="w-full h-auto mx-auto p-3 mb-4"
                                src={`/img/days/${day}.png`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ServiceSection;
