import { useRef } from "react";

const CategoryScroll = ({ categories, handleCategoryClick, selectedCategory }) => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -200, // Scroll amount
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 200, // Scroll amount
      behavior: "smooth",
    });
  };

  return (
    <div className="relative mb-8">
      {/* Sol ok */}
      <button
        onClick={scrollLeft}
        className="absolute left-0 top-1/2 transform -translate-y-1/2  p-2 rounded-full z-10"
      >
        &#8592;
      </button>

      {/* Kategoriler */}
      <div ref={scrollRef} className="grid grid-rows-2 grid-flow-col gap-4 overflow-x-auto no-scrollbar">
        {categories.map((menu) => (
          <button
            key={menu._id}
            onClick={() => handleCategoryClick(menu._id)}
            className={`px-4 py-2 rounded-md ${
              selectedCategory === menu._id ? "bg-orange-500 text-white" : "bg-gray-200 text-gray-700"
            } hover:bg-orange-600 hover:text-white focus:outline-none whitespace-nowrap`}
          >
            {menu.name}
          </button>
        ))}
      </div>

      {/* Sağ ok */}
      <button
        onClick={scrollRight}
        className="absolute right-0 top-1/2 transform -translate-y-1/2  p-2 rounded-full z-10"
      >
        &#8594;
      </button>
    </div>
  );
};

export default CategoryScroll;