import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Menu() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const menuLinks = [
    { to: "/", text: "Home" },
    { to: "/Contact", text: "Contact" },
    { to: "/Order", text: "See Menu & Order" },
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://app.gyrosnj.com/categories");
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div>
      {isMobileMenuOpen && (
        <div className="fixed left-0 top-0 w-full h-full z-50 bg-black bg-opacity-30 shadow">
          <div className="absolute left-0 top-0 w-72 h-full z-50 bg-primary-light text-green-900">
            <div
              onClick={toggleMobileMenu}
              className="absolute right-3 top-3 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>

            <h3 className="font-lora font-semibold p-6 text-3xl">Menu</h3>

            <div>
              {menuLinks.map((link, index) => (
                <Link key={index} to={link.to}>
                  <p className="block px-4 py-2 font-semibold font-roboto hover:underline">
                    {link.text}
                  </p>
                </Link>
              ))}


              {
                categories.map((category) => (
                  <Link key={category._id} to={`/Order`}>
                    <p className="block px-4 ml-1 font-[400px] font-roboto hover:underline">
                      - {category.name.charAt(0).toUpperCase() + category.name.slice(1).toLowerCase()}
                    </p>
                  </Link>
                ))
              }


            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto flex items-center justify-between">
        <div className="text-white text-lg font-bold uppercase rounded-lg p-2">
          <Link className="flex items-center" to="/">
            <img alt="" className="w-44 md:w-56" src="../img/logo3.png" />
          </Link>
        </div>

        <div className="hidden md:flex space-x-10 ml-28 m items-right">
          {menuLinks.map((link, index) => (
            <Link key={index} to={link.to} className="text-white">
              {link.text}
            </Link>
          ))}
          <a href="#about" className="text-white">
            About Us
          </a>
          <a href="#materials" className="text-white">
            Business Hours
          </a>
        </div>

        <div className="flex items-center ml-auto">
          <div className="hidden md:flex">
            <Link
              to="/Order"
              className="text-white font-bold bg-orange-500 hover:bg-orange-600 py-2 px-4 rounded-lg"
            >
              See Menu & Order
            </Link>
          </div>

          <div className="md:hidden">
            <Link
              to="/Order"
              className="text-sm block text-white font-bold bg-orange-500 hover:bg-orange-600 py-2 px-4 rounded-lg ml-auto"
            >
              See Menu & Order
            </Link>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <p
            onClick={toggleMobileMenu}
            className="text-xl lg:hidden flex text-green-900"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Menu;
