import React, { useState } from "react";
import emailjs from "emailjs-com";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    subject: "",
    description: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceID = "service_8vgvdfp";
    const templateID = "template_m0kynwa";
    const userID = "CpQ6yR556h5Kvm7bX";

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Your message has been sent successfully!");
      })
      .catch((err) => {
        console.log("FAILED...", err);
        alert("Failed to send your message. Please try again.");
      });
  };

  return (
    <div className="w-full">
      <div className="bg-primary-light dark:bg-primary-dark text-second-light dark:text-second-dark h-96"></div>

      <div className="max-w-5xl mx-auto px-6 mb-12">
        <div className="bg-white w-full shadow rounded-2xl p-8 -mt-80">
          <p className="text-3xl font-bold text-center"> Contact Me</p>

          <form onSubmit={handleSubmit}>
            <div className="md:flex items-center mt-12">
              <div className="w-full md:w-1/2 flex flex-col p-2">
                <label className="font-semibold">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="text-gray-800 border border-black p-3 rounded-md bg-gray-200"
                  required
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col p-2">
                <label className="font-semibold">Surname</label>
                <input
                  type="text"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  className="text-gray-800 border border-black p-3 rounded-md bg-gray-200"
                  required
                />
              </div>
            </div>

            <div className="md:flex items-center mt-12">
              <div className="w-full flex flex-col p-2">
                <label className="font-semibold">Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="text-gray-800 border border-black p-3 rounded-md bg-gray-200"
                  required
                />
              </div>
            </div>

            <div className="md:flex items-center mt-12">
              <div className="w-full flex flex-col p-2">
                <label className="font-semibold">Description</label>
                <textarea
                  name="description"
                  rows={10}
                  value={formData.description}
                  onChange={handleChange}
                  className="text-gray-800 border border-black p-3 rounded-md bg-gray-200"
                  required
                />
              </div>
            </div>

            <div className="md:flex items-center mt-12">
              <div className="w-full flex flex-col p-2">
                <button type="submit" className="bg-primary-light px-4 py-5 rounded-xl shadow-lg dark:bg-primary-dark text-white dark:text-second-dark">
                  Send me
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
